export const formatDataArrayForTable = (arr = [], col_model = [], format = (item) => {return item}) => {
    let rows = [], row
    arr.forEach(item => {
        row = []
        col_model.forEach(model => {
            row.push(format(model.value, item))
            /*if (Object.keys(item).includes(model.value)) {
                row.push(item[model.value])
            }*/
        })
        rows.push(row)
    })
    return rows
}

export const setHeader = (e) => {
    let header = "<tr>"
    e.forEach(row => {
        header += "<th>" + row.name + "</th>"
    })
    header += "</tr>"
    return header
}

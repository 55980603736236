<template>
  <button class="btn btn-outline-primary" @click="handleAlphaButtonClick">
    {{ text }}
  </button>
</template>

<script>

export default {
  name: "AlphaListItem",
  props: {
    text: String
  },
  emits: ['alphaButtonClicked'],
  setup(props, context) {
    const handleAlphaButtonClick = (event) => {
      context.emit('alphaButtonClicked', event.target.innerText.toString().toLowerCase())
    }

    return {
      handleAlphaButtonClick
    }
  }
}
</script>

import store from '@/store/index'

export default class DisplayModel {

  static getDisplays(params = {}) {
    return new Promise((resolve, reject) =>
      store.dispatch('getfromdatabase', Object.assign(params, {
        route: 'dealer::getdisplaylist'
      }))
        .then(result => {
          this.setDisplays(result.data.data.list)
          resolve()
        })
        .catch(error => reject(error)))
  }

  static updateDisplayControllers(params = {}) {
    return new Promise((resolve, reject) =>
      store.dispatch('getfromdatabase', Object.assign(params, {
        route: 'dealer::updatedisplaycontrollers'
      }))
        .then(result => {
          this.setDisplays(result.data.data.list)
          resolve()
        })
        .catch(error => reject(error)))
  }

  static getDisplaysList() {
    return store['getters']['getDisplaysFromStore']
  }

  static setDisplays(result) {
    return store.dispatch('setDisplayList', result)
  }
}

import store from '@/store/index'
import UserModel from "./UserModel"

export default class CompanyModel {

  static getCompanyProfiles(params = {}) {
    return new Promise((resolve, reject) =>
      store.dispatch('getfromdatabase', Object.assign(params, {
        sessionkey: UserModel.getSessionkey(),
        route: 'dealer::getcompanylist'
      }))
        .then(result => {
          if (Object.keys(result.data).length > 0) {
            if (Object.keys(result.data).includes("code") && result.data.code !== 200) {
              reject(result.data.code)
            } else if (Object.keys(result.data.data).length > 0) {
              this.setCompanies(result.data.data.list)
            } else {
              this.setCompanies([])
            }
          }
          resolve()
        })
        .catch(error => reject(error)))
  }

  static getCompanyProfilesList() {
    return store['getters']['getCompanyProfilesFromStore']
  }

  static getSelectedCompany() {
    return store['getters']['getSelectedCompany']
  }

  static setCompanies(result) {
    return store.dispatch('setCompanyList', result)
  }

  static setSelectedCompany(company) {
    return store.dispatch('setSelectedCompany', company)
  }

  static setSelectedCompanyByIndex(index) {
    return store.dispatch('setSelectedCompanyByIndex', index)
  }
}

<template>
  <div class="row mb-5">
    <div class="btn-toolbar justify-content-between btn-group-sm" role="group">
      <alpha-list-item v-for="letter in letters" :text="getLetter(letter)" :key="letter"
                       @alphaButtonClicked="handleAlphaButtonClicked" :disabled="isLoading"
      ></alpha-list-item>
    </div>
  </div>
</template>

<script>
import {onMounted, ref} from "vue"
import AlphaListItem from "@/components/AlphaListItem"

export default {
  name: "AlphaListGroup",
  emits: ['alphaButtonClicked'],
  props: {
    isLoading: Boolean
  },
  setup(props, context) {

    let letters = ref([])

    const getLetter = (value) => {
      return String.fromCharCode(65 + value)
    }

    const handleAlphaButtonClicked = (search) => {
      context.emit('alphaButtonClicked', search)
    }

    const loop = () => {
      for (let i = 0; i < 26; i++) {
        letters.value.push(i)
      }
      letters.value.push(-30)
    }

    onMounted(() => {
      loop()
    })

    return {
      getLetter,
      handleAlphaButtonClicked,
      letters
    }
  },
  components: {
    AlphaListItem
  }
}
</script>

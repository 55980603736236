<template>
  <div class="modal" id="modalConfirmLogin" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header bg-primary text-white">
          <h5 class="modal-title">Warning</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                  @click="handleCloseModal"></button>
        </div>
        <div class="modal-body">
          <p>Before continuing, please close any additional SM Infinity browser tabs or windows, then click the Continue
            button below. You can only access one customer at a time or errors will occur.</p>
        </div>
        <div class="modal-footer justify-content-between">
          <button type="button" class="btn btn-success" @click="loginAsSelectedClient">Continue</button>
          <button type="button" class="btn btn-secondary" @click="handleCloseModal">Cancel</button>
        </div>
      </div>
    </div>
  </div>
  <div class="container mb-5">
    <nav class="navbar navbar-dark" style="background-color:#FC7307">
      <div class="container-fluid">
        <a class="navbar-brand" href="#">
          <img src="../assets/fav.png" width="30" height="30" class="d-inline-block align-top" alt="">
          Dealer Portal v1.0.8
        </a>
        <button type="button" class="btn btn-light" @click="logout">Logout</button>
      </div>
    </nav>
  </div>
  <div class="container">
    <div class="spinner-border text-primary" role="status" v-if="is_loading">
      <span class="visually-hidden">Loading...</span>
    </div>
    <div class="row justify-content-center">
      <div class="col-8">
        <alpha-list-group @alphaButtonClicked="getValuesForListBox" :isLoading="isDataLoading"></alpha-list-group>
      </div>
    </div>
    <template v-if="is_loading_companies">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </template>
    <template v-else-if="hasCompanies">
      <div class="row mb-3">
        <form class="needs-validation" novalidate @submit.prevent>
          <div class="mb-3 row justify-content-center">
            <label for="listCompanyName" class="col-sm-2 col-form-label">Login As: </label>
            <div class="col-sm-5">
              <input list="listCompanyName" id="inputSearchCompanyName" type="text" class="form-control"
                     autocomplete="off" @change="companyListChangeHandler">
              <datalist id="listCompanyName">
                <option v-for="(item, index) in company_list" :key="index">
                  {{ item.businessname }}
                </option>
              </datalist>
            </div>
            <div class="col-1">
              <button class="btn btn-secondary" @click="clearCompanyListSelection">Clear
              </button>
            </div>
            <div class="col-1">
              <button class="btn btn-primary" :disabled="!isSelectedCompany" @click="openConfirmModal">Go</button>
            </div>
          </div>
        </form>
      </div>

    </template>
    <div class="row">
      <table id="tableDisplays" class="table table-striped table-bordered table-hover" :style="{'opacity':opacity}">
        <thead></thead>
        <tbody></tbody>
      </table>
    </div>
  </div>
  <vue-notification-list :position="position"></vue-notification-list>
</template>
<script>

import CompanyModel from "@/store/models/CompanyModel"
import DisplayModel from "@/store/models/DisplayModel"
import SiteModel from "@/store/models/SiteModel"
import UserModel from "@/store/models/UserModel"
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery'
import {formatDataArrayForTable, setHeader} from "@/plugins/datatable"
import {formatTimestampForGUI} from "@/plugins/datetime"
import {generateKey, getBrowser} from "@/plugins/util"
import AlphaListGroup from "@/components/AlphaListGroup"
import {useNotificationStore} from '@dafcoe/vue-notification'

const {setNotification} = useNotificationStore()

export default {
  data() {
    return {
      opacity: 0,
      browser: {},
      company_list: [],
      display_col_model: [
        {name: "Project", value: "displayserialid", width: "100px"},
        {name: "Schedules", value: "schedules", width: "10px"},
        {name: "Alias", value: "alias"},
        {name: "Location", value: "location", width: "150px"},
        {name: "Last Check In (PST)", value: "lastcheckin", width: "100px"}
      ],
      display_list: [],
      is_loading: true,
      is_loading_companies: false,
      number_search: "'1','2','3','4','5','6','7','8','9','0'",
      position: "top-right",
      route: "",
      selected_company: -1
    }
  },
  mounted() {
    this.getRoute()
    $(setHeader(this.display_col_model)).appendTo("#tableDisplays thead")
    $('#tableDisplays').DataTable({
      searching: false,
      info: false,
      lengthChange: false,
      columns: this.display_col_model,
      paging: false
    })
    this.browser = getBrowser()
  },
  methods: {
    clearCompanyListSelection() {
      this.selected_company = -1
      $('#inputSearchCompanyName').val("")
      this.display_list = []
      this.fillDataTable()
    },
    companyListChangeHandler(e) {
      const name = e.target.value.toString().toLowerCase()
      const selected = this.company_list.filter(company => company.businessname.toString().toLowerCase() === name)
      if (selected.length) {
        this.selected_company = selected[0].companyid
        this.getDisplaysList(this.selected_company)
      }
    },
    fillDataTable() {
      $('#tableDisplays').DataTable().clear().rows.add(formatDataArrayForTable(this.display_list, this.display_col_model, (model, value) => {
        let response = "", location = []
        switch (model) {
          case "lastcheckin":
            switch (this.browser.os.toLowerCase()) {
              case "mac os":
                response = (value.lastcheckin === "Not Available" || value.lastcheckin === "0000-00-00 00:00:00") ? "Not Available" : value.lastcheckin
                break
              default:
                response = (value.lastcheckin === "Not Available" || value.lastcheckin === "0000-00-00 00:00:00") ? "Not Available" : formatTimestampForGUI(value.lastcheckin)
            }
            break
          case "location":
            if (value.city !== null) {
              location.push(value.city)
            }
            if (value.state !== null) {
              location.push(value.state)
            }
            switch (location.length) {
              case 2:
                response = location.join(",")
                break
              case 1:
                response = location[0]
                break
              default:
                response = ""
            }
            break
          case "schedules":
            if (parseInt(value['schedules']) === 0) {
              response = "<i class='far fa-calendar-times' style='color:red' data-bs-toggle='tooltip' data-bs-html='true' " +
                  "title='This project has 0 schedules available to play today and therefore may be blank.'></i>"
            } else {
              response = "<i class='fas fa-calendar-check' style='color:blue' data-bs-toggle='tooltip' data-bs-html='true' " +
                  "title='This project has at least 1 schedule available to play today.'></i>"
            }
            break
          default:
            response = value[model]
        }
        return response
      })).draw()
    },
    getCompanyProfilesList(search) {
      CompanyModel.getCompanyProfiles({search: search})
          .then(() => {
            this.company_list = CompanyModel.getCompanyProfilesList()
            this.is_loading_companies = false
            if (this.hasCompanies) {
              this.opacity = 1
            } else {
              this.opacity = 0
              setNotification({message: "There are no companies for this selection.", type: "warning"})
            }
          })
          .catch((error) => {
            this.is_loading_companies = false
            if (error === 501) {
              setNotification({message: "There are no companies for this.", type: "alert"})
            } else {
              setNotification({message: "There was an error retrieving data.", type: "alert"})
            }
          })
    },
    getDisplaysList(selected) {
      DisplayModel.getDisplays({search: "companyid = " + selected})
          .then(() => {
            this.display_list = DisplayModel.getDisplaysList()
            this.fillDataTable()
          })
          .catch(() => {
            setNotification({message: "There was an error retrieving data.", type: "alert"})
          })
    },
    getValuesForListBox(search) {
      this.is_loading_companies = true
      this.opacity = 0
      this.company_list = []
      this.clearCompanyListSelection()
      if (search === "#") {
        this.getCompanyProfilesList(this.number_search)
      } else {
        this.getCompanyProfilesList("'" + search + "'")
      }
    },
    getRoute() {
      SiteModel.getRoute()
          .then(result => {
            this.route = result.route[0].path + "/" + result.route[0].webpage + "?q=" + generateKey(60) + "&x=" + UserModel.getSessionkey()
            this.is_loading = false
          })
          .catch(() => {
            setNotification({message: "There was an error retrieving data.", type: "alert"})
          })
    },
    handleCloseModal() {
      $('#modalConfirmLogin').hide()
    },
    loginAsSelectedClient() {
      this.handleCloseModal()
      const company_id = this.selected_company
      let displays = this.display_list.map(display => {
        return display.displayid
      }).join()
      UserModel.update({companyid: company_id}).then(() => {
        UserModel.updateSession({companyid: company_id}).then(() => {
          DisplayModel.updateDisplayControllers({displays: displays}).then(() => {
            window.open(this.route, '_blank')
          })
        })
      })
    },
    logout() {
      this.handleCloseModal()
      UserModel.revert().then(() => {
        UserModel.logout().then(() => {
          this.$router.replace("/")
        }).catch(() => {
          setNotification({message: "There was an error in logging you out.", type: "alert"})
        })
      })
    },
    openConfirmModal() {
      SiteModel.getSiteStatus()
          .then(result => {
            if (result.status.status !== "operational") {
              this.$router.replace("/")
            } else {
              $('#modalConfirmLogin').show()
            }
          })
          .catch(() => {
            this.$router.replace("/")
          })
    }
  },
  computed: {
    hasCompanies() {
      return this.company_list.length > 0
    },
    isDataLoading() {
      return this.is_loading
    },
    isSelectedCompany() {
      return this.selected_company > -1
    }
  },
  components: {
    AlphaListGroup
  }
}
</script>
